import React, { useRef, useState, useContext } from 'react'
import './Style/Login.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Card, Alert, Button, TextField, Box, Typography, Grid, Avatar, Stepper, Step, StepLabel } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Toaster, toast } from 'sonner'
import CircularProgress from '@mui/material/CircularProgress';

export default function LoginPage() {

  const [activeStep, setActiveStep] = useState(0);
  const containerRef = useRef(null);
  const [formType, setFormType] = useState('register');
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);

  const registerBtnClick = () => {
    containerRef.current.classList.add('active');
    setFormType('register');
  };


  const loginBtnClick = () => {
    containerRef.current.classList.remove('active');
  };

  const handleNext = async (event) => {
    event.preventDefault(); 

    try {
      if (activeStep === 0) {
        // Step 0: Send OTP
        if (formData.email && formData.username && formData.mobileNo) {
          setLoading(true); // Show loader
          try {
            const response = await axios.post("https://www.empairindia.com/api/send-otp", {
              email: formData.email,
              mobileNo: formData.mobileNo,
              username: formData.username,
            });
            console.log("OTP sent successfully:", response.data.message);
            alert(response.data.message); // Show success message
            setActiveStep((prevActiveStep) => prevActiveStep + 1); // Proceed to OTP step
          } catch (error) {
            setLoading(false); // Hide loader
            if (error.response && error.response.data && error.response.data.error) {
              // Show error message returned by the server
              alert(error.response.data.error);
            } else {
              // Show generic error message if server response is not available
              alert("An error occurred while sending the OTP. Please try again.");
            }
            console.error("Error sending OTP:", error);
          } finally {
            setLoading(false); // Ensure loader is hidden after request
          }
        } else {
          alert("Please provide email, username, and mobile number.");
        }
      }

      else if (activeStep === 1) {
        // Step 1: Verify OTP
        const response = await axios.post("https://www.empairindia.com/api/verify-otp", {
          email: formData.email,
          otp: otp.join(''),
        });
        console.log("OTP verified successfully");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 2) {
        // Step 2: Submit Password
        if (formData.pin && formData.confirmPin) {
          if (formData.pin === formData.confirmPin) {
            try {
              const response = await axios.post("https://www.empairindia.com/api/update-password", {
                email: formData.email,
                pin: formData.pin,
              });

              // Check backend response and display appropriate messages
              if (response.data.message === "PIN created successfully.") {
                console.log("PIN created successfully");
                alert(response.data.message);
                setActiveStep((prevActiveStep) => prevActiveStep + 1); // Move to the success message step
              } else if (response.data.message === "This PIN is already in use. Please try another one.") {
                console.log("Duplicate PIN error");
                alert(response.data.message);
              } else {
                console.log("Unexpected response from server");
                alert("An unexpected response was received from the server.");
              }
            } catch (error) {
              console.error("Error while updating PIN:", error.response?.data?.message || error.message);
              alert(error.response?.data?.message || "An error occurred while updating your PIN. Please try again.");
            }
          } else {
            alert("PINs do not match. Please try again.");
          }
        } else {
          alert("Please provide and confirm your PIN.");
        }
      } else if (activeStep === 3) {
        console.log("Successfully updated user profile");
        alert("Your profile has been updated successfully!");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loader when process is finished
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Register 

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };


  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    Confirm_Password: '',
    pin: '',
    confirmPin: '',
    otp: "",
    mobileNo: "",
  });

  const Loader = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10, // Ensure it's on top
        backgroundColor: 'rgba(255, 255, 255, 0.8)' // Slight overlay effect
      }}
    >
      <CircularProgress />
    </Box>
  );

  const steps = ['Personal Information', 'Verification', 'Password'];
  const getStepContent = (step) => {
    return (
      <div className={`step-content ${activeStep === step ? 'active' : ''}`} style={{ position: 'relative' }}>
        {loading && <Loader />} {/* Loader will appear inside the form */}
        {step === 0 && !loading && (
          <>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Mobile Number"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {  // Ensures only up to 10 digits can be entered
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    mobileNo: value,
                  }));
                }
              }}
              required
              InputProps={{
                style: { borderRadius: "8px" },
                inputProps: { maxLength: 10 },  // Ensures only 10 digits can be entered
              }}
            />
          </>
        )}

        {step === 1 && !loading && (
          <Box display="flex" justifyContent="space-between" gap={1}>
            {otp.map((digit, index) => (
              <TextField
                key={index}
                id={`otp-${index}`}
                variant="outlined"
                margin="normal"
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center", borderRadius: "8px" }
                }}
                required
              />
            ))}
          </Box>
        )}

        {step === 2 && !loading && (
          <>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Pin"
              name="pin"
              type="password"
              value={formData.pin}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Confirm Pin"
              name="confirmPin"
              type="password"
              value={formData.confirmPin}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
          </>
        )}

        {step === 3 && !loading && <h2>Your profile has been created!</h2>}
      </div>
    );
  };



  const [message, setMessage] = useState('');
  const [pin, setPin] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);


  const [step, setStep] = useState(1); // 1: Email Input, 2: OTP Sent, 3: OTP Input, 4: Change PIN
  const [email, setEmail] = useState("");
  const [otpLog, setOtpLog] = useState(["", "", "", ""]); // For a 4-digit OTP
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const handleEmailSubmit = async () => {
    if (!email) {
      toast.error("Please enter your email.");
      return;
    }

    try {
      const response = await fetch("https://www.empairindia.com/api/resetPin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message || "OTP sent successfully!");
        setStep(2); // Move to the OTP Sent step
      } else {
        toast.error(data.message || "Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("An error occurred while sending OTP.");
    }
  };

  const handleOtpSubmit = async () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length !== 4) {
      toast.error("Please enter a valid 4-digit OTP.");
      return;
    }

    try {
      const response = await fetch("https://www.empairindia.com/api/verify-otp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, otp: enteredOtp }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message || "OTP verified successfully!");
        setStep(4); // Move to Change PIN step
      } else {
        toast.error(data.message || "OTP verification failed.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An error occurred while verifying OTP.");
    }
  };

  const handleChangePinSubmit = async () => {
    if (!newPin || !confirmPin) {
      toast.error("Please fill out both PIN fields.");
      return;
    }
    if (newPin !== confirmPin) {
      toast.error("PINs do not match.");
      return;
    }

    try {
      const response = await fetch("https://www.empairindia.com/api/updatePin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, newPin }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message || "PIN successfully updated!");
        // setStep(1); // Reset to the first step
        window.location.reload();  
      } else {
        toast.error(data.message || "Failed to update PIN.");
      }
    } catch (error) {
      console.error("Error updating PIN:", error);
      toast.error("An error occurred while updating the PIN.");
    }
  };

  const handleOtpLogChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleBackLog = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1)); // Navigate to the previous step
  };

  //
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');

  const handleNextClick = async (event) => {
    event.preventDefault();
    setError('');
    setUserData(null);

    if (!/^[0-9]{4}$/.test(pin)) {
      setError('PIN must be a 4-digit number');
      return;
    }

    try {
      const response = await axios.post('https://www.empairindia.com/api/login', { pin });
      const userDetails = response.data.data;
      setUserData(userDetails);
      toast.success(
        `Login Successful! 
        Username: ${userDetails.username},
        Email: ${userDetails.email}, 
        Mobile No: ${userDetails.mobileNo}`
      );
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);

        // Show toast for "No user found" or other error messages
        if (err.response.status === 404) {
          toast.error('No user found with this PIN');
        } else {
          toast.error(err.response.data.message);
        }
      } else {
        setError('Something went wrong');
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className='RegMain' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '2rem' }}>
      <div className='logContainer' id='logContainer' ref={containerRef} style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', height: '1000px' }}>
        <Card className="logContainer" style={{ width: '100%', padding: '2rem', marginLeft: '0', marginTop: '10rem' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar className="register-avatar" style={{ marginBottom: '1rem', marginLeft: "35rem" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="register-title" style={{ marginBottom: '1rem', marginLeft: "35rem" }}>
              Register
            </Typography>
            {message && <Alert severity="error" style={{ width: '100%', marginBottom: '1rem' }}>{message}</Alert>}
            <form style={{ width: '100%', maxWidth: '600px', marginTop: '1rem', marginLeft: "35rem" }}>
              <Stepper activeStep={activeStep} style={{ marginBottom: '1rem' }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel sx={{ color: 'green' }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {getStepContent(activeStep)}

              <Box display="flex" justifyContent="space-between" style={{ marginTop: '1rem' }}>
                {activeStep !== 0 && activeStep !== 3 && (
                  <button onClick={handleBack} className="register-button" type="button">
                    Back
                  </button>
                )}
                {activeStep === 3 ? (
                  <button
                    onClick={() => {
                      setFormData({
                        username: "",
                        email: "",
                        password: "",
                        Confirm_Password: '',
                        pin: '',
                        confirmPin: '',
                        otp: "",
                        mobileNo: "",
                      });
                      setActiveStep(0); 
                      loginBtnClick();
                    }}
                    className="register-button"
                    type="button"
                  >
                    Login
                  </button>
                ) : (
                  <button onClick={handleNext} className="register-button" type="button">
                    Next
                  </button>
                )}
              </Box>

              <Grid container justifyContent="center" style={{ marginTop: '1rem' }}>
                <Grid item>
                  <Link to="/login" className="link-text">
                    Already have an account?
                  </Link>
                  <span className='' id='login' onClick={loginBtnClick}> Login</span>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        <Box className='form-logContainer sign-in' sx={{ marginTop: '2rem' }}>
          {!isOtpVisible && (

            <form
              onSubmit={(event) => {
                handleNextClick(event);
              }}
              style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>

              <TextField
                type="text"
                placeholder="Enter 4-digit PIN"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                fullWidth
                style={{ marginBottom: '1rem' }}
              />
              <Grid container justifyContent="center" style={{ marginTop: '1rem' }}>
                <Grid item>
                  <Link
                    to="#"
                    className="link-text"
                    onClick={() => setIsOtpVisible(true)}
                  ><Typography
                    variant="body2"
                    sx={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIsOtpVisible(true)}
                  >
                      Forgot your password?
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              {message && <Typography color="error">{message}</Typography>}

              <Button type="submit" variant="contained" fullWidth>
                Login
              </Button>
            </form>

          )}
          {isOtpVisible ? (
            <form style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
              <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          {step === 1 && (
            <>
              <Typography variant="h5" gutterBottom>
                Enter Your Email
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleEmailSubmit}>
                Send OTP
              </Button>
            </>
          )}

          {step === 2 && (
            <>
              <Typography variant="h6" gutterBottom>
                OTP Sent to {email}
              </Typography>
              <Button variant="contained" color="primary" onClick={() => setStep(3)}>
                Enter OTP
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleBackLog}>
                Back
              </Button>
            </>
          )}

          {step === 3 && (
            <>
              <Typography variant="h6" gutterBottom>
                Enter OTP to Reset Password
              </Typography>
              <Box display="flex" justifyContent="space-between" gap={1}>
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    id={`otp-${index}`}
                    variant="outlined"
                    margin="normal"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center", width: "3rem" },
                    }}
                    required
                  />
                ))}
              </Box>
              <Button variant="contained" color="primary" onClick={handleOtpSubmit}>
                Submit OTP
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleBackLog}>
                Back
              </Button>
            </>
          )}

          {step === 4 && (
            <>
              <Typography variant="h6" gutterBottom>
                Change Your PIN
              </Typography>
              <TextField
                label="New PIN"
                type="password"
                variant="outlined"
                value={newPin}
                onChange={(e) => setNewPin(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Confirm PIN"
                type="password"
                variant="outlined"
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleChangePinSubmit}>
                Submit
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleBackLog}>
                Back
              </Button>
            </>
          )}
        </Box>
            </form>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h5" gutterBottom>
                Login or Reset Password
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => console.log('Login or Reset Action')}
              >
                Submit
              </Button>
            </Box>

          )}
        </Box>
        <div className='toggle-logContainer'>
          <div className='toggle'>
            <div className='toggle-panel toggle-left'>
              <h1>Welcome Back!</h1>
              <p>Enter your personal details to use all of the site's features</p>
              <button className='hidden' id='login' onClick={loginBtnClick}>Login</button>
            </div>
            <div className='toggle-panel toggle-right'>
              <h1>EmpairIndia</h1>
              <p>Register with your personal details to use all of the site's features</p>
              <button className='hidden' id='register' onClick={registerBtnClick}>Register</button>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            fontSize: '15px',  
          },
        }}
        expand={true}
        richColors
      />

    </div>
  )
}